import React, { useEffect, useRef, useState } from "react";
import Layout from "../component/layout/Layout";
import { Helmet } from "react-helmet";
import { Button, Card, Select, Form, Input, Breadcrumb, InputNumber, Row, Col, Collapse, Tooltip} from "antd";
import { itemRender } from '../utils/common'
import { HomeOutlined, PlusOutlined, MinusOutlined, CloseOutlined, SaveFilled, EditFilled } from '@ant-design/icons';
import { getDocumentType } from '../services/generateDocument'
import { postCustomer, postDocumentSetting, getDocumentSetting, putCustomer, putDocumentSetting, } from '../services/settingDocument'
import { useNavigate } from 'react-router'
import Notification from "../services/Notification";
import SkeletonLoading from '../component/SkeletonLoading';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { useParams, useLocation } from 'react-router-dom';
const { Panel } = Collapse;

const { TextArea } = Input;

const App = () => {
  const initialized = useRef(false);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const Noti = new Notification()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [pageAction, setPageAction] = useState('');
  const [activeKey, setActiveKey] = useState(["1", "2"]);
  const [invoiceNoteCount, setInvoiceNoteCount] = useState(0);
  const [deliveryNotesCount, setDeliveryNotesCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState(null);
  const [docType, setDocType] = useState([]);
  const [allType, setAllType] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const itemMenu = [
    {
      href: `/setting-document`,
      title: (
        <>
          <span><HomeOutlined style={{marginRight:"10px"}}/>Setting Document</span>
        </>
      )
    },{
      href: ``,
      title: (
        <>
          <span>{pageAction || "Add"}</span>
        </>
      )
    },
  ]
  const initialValues = {
    "customerCode": '',
    "customerName": '',
    "Invoice": 1,
    "Invoice (copy)": 0,
    "Receipt": 0,
    "Receipt (copy)": 0,
    "Billing note": 0,
    "invoiceNote": "",
    "Delivery note": 1,
    "Delivery note (copy)": 0,
    "Packing list": 0,
    "Packing list (copy)": 0,
    "deliveryNote": "",
  };
  const initialValues2 = {
    name : null,
    nameOther : "",
    qty : 1,
  };

useEffect(() => {
  const initialize = async () => {
    if (!initialized.current) {
      setLoadingFirst(true);
      initialized.current = true;
      const path = location.pathname;
      await fnGetDocumentType();
      if (path.includes('/add')) {
        setPageAction('Add');
      } else if (path.includes('/edit')) {
        setPageAction('Edit');
        await fnGetSetting();
      } else if (path.includes('/view')) {
        setPageAction('View');
        await fnGetSetting();
      }
      setLoadingFirst(false);
    }
  };

  initialize();
}, []);

  const CustomPanelHeader = ({ title, expanded }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{fontWeight:600, fontSize:"18px"}}>{title}</span>
      {expanded ? (
        <Arrow height={20} width={20} style={{ transform: "rotate(90deg)", color: "#63A8C3" }} />
      ) : (
        <Arrow height={20} width={20} style={{ transform: "rotate(-90deg)", color: "#63A8C3" }} />
      )}
    </div>
  );

  const fnGetDocumentType = async () => {
    try {
      const res = await getDocumentType({});
      let resData = res.data;
      if (resData.resultCode === "20000") {
        let docOther = [];
        let allType = [];
        resData.resultData.forEach(item => {
          item.isOther ? docOther.push(item) : allType.push(item);
        });
  
        await setAllType(allType);
        await setDocType(docOther);
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        });
      }
    } catch (error) {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      });
    } finally {
      setLoadingFirst(false);
      setLoadingApi(false);
    }
  };
 
  const fnGetSetting = async () => {
    setLoadingApi(true);
    try {
      const res = await getDocumentSetting({ customerCode: id });
      let resData = res.data;
      if (resData.resultCode === "20000") {
        console.log(resData);
        const formFields = {
          "customerCode": resData.resultData.customer.customerCode,
          "customerName": resData.resultData.customer.customerName,
          "phoneNumber": resData.resultData.customer.phoneNumber,
          "invoiceNote": resData?.resultData?.customer?.invoiceNote,
          "deliveryNote": resData?.resultData?.customer?.deliveryNote
        };
        setInvoiceNoteCount(resData?.resultData?.customer?.invoiceNote?.length)
        setDeliveryNotesCount(resData?.resultData?.customer?.deliveryNote?.length)
        let other = [];
        resData.resultData.documents.forEach(doc => {
          formFields[doc.documentTypeName] = +doc.qty;
          if (doc.isOther) {
            other.push({
              doctypeId: doc.documentTypeId,
              documentTypeName: doc.documentTypeName,
              qty: +doc.qty
            });
          }
        });
  
        form.setFieldsValue(formFields);
        if (other.length > 0) {
          setOtherData(other);
          const otherIds = other.map(doc => doc.doctypeId);
          setDocType(prevState => {
            const updatedDocType = prevState.filter(item => !otherIds.includes(item.documentTypeId));
            return updatedDocType;
          });
        }
      } else {
        Noti.error({
          message: 'Error',
          description: resData.resultDescription || ''
        });
      }
    } catch (error) {
      console.log("🚀 ~ getDocumentSetting ~ error:", error);
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      });
    } finally {
      setLoadingApi(false);
    }
  };
  
  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleInvoiceNoteChange = (e) => {
    setInvoiceNoteCount(e.target.value.length);
  };

  const handleDeliveryNotesChange = (e) => {
    setDeliveryNotesCount(e.target.value.length);
  };

  const addOther = (e) => {
    let add = {}
    if(e.name == "Other"){
      if(otherData.find(x=>x.documentTypeName.toLowerCase() == e.nameOther.toLowerCase())){
        Noti.warning({
          message: 'Duplicate',
          description: "Duplicate name"
        })
        return
      }
      add.documentTypeName = e.nameOther
      add.qty = e.qty
      setOtherData(prevState => [...prevState, add])
      form2.resetFields(['nameOther', 'qty']);
    }else{
      if(otherData.find(x=>x.documentTypeName.toLowerCase() == selectedValue.label.toLowerCase())){
        Noti.warning({
          message: 'Duplicate',
          description: "Duplicate name"
        })
        return
      }
      add.doctypeId = selectedValue.value
      add.documentTypeName = selectedValue.label
      add.qty = e.qty
      setOtherData(prevState => [...prevState, add])
      setDocType(prevState => prevState.filter(item => item.documentTypeId !== selectedValue.value));
      setSelectedValue(null);
      form2.resetFields(['name', 'nameOther', 'qty']);
    }
  }

  const removeOther = (index) => {
    const removedItem = otherData[index];
    setOtherData(prevState => prevState.filter((_, i) => i !== index));
    
    if (removedItem.doctypeId) {
      setDocType(prevState => [...prevState, { documentTypeId: removedItem.doctypeId, documentTypeName: removedItem.documentTypeName }]);
    }
  };
  
  const postSetting = async (e) => {

    let payloadCus = {
      customerCode : e.customerCode,
      customerName : e.customerName,
      invoiceNote : e.invoiceNote,
      deliveryNote : e.deliveryNote,
      phoneNumber : e.phoneNumber
    }
    let payloadSetting = {
      customerCode : e.customerCode,
      documents : otherData.map(doc => doc.doctypeId ? { doctypeId: doc.doctypeId, qty: doc.qty } : { docTypeName: doc.documentTypeName, qty: doc.qty })
    }
    for (let i = 0; i < allType.length; i++) {
      let docName = allType[i].documentTypeName;
      let qty = e[docName];
    
      if (qty !== undefined) {
        payloadSetting.documents.push({
          doctypeId: allType[i].documentTypeId,
          qty: qty || 0
        });
      }
    }
    try {
      if(pageAction == "Add"){
        let resCus = await postCustomer(payloadCus)
        if(resCus.data.resultCode == "20100"){
          await postDocumentSetting(payloadSetting)
        }else{
          Noti.warning({
            message: 'Duplicate',
            description: "Duplicate Customer ID detected. Please check the data integrity."
          })
          return
        }
      }else{
        let resCus = await putCustomer(payloadCus)
        if(resCus.data.resultCode == "20000"){
          await putDocumentSetting(payloadSetting)
        }else{
          Noti.error({
            message: 'Error',
            description: resCus.resultDescription || ''
          })
          return
        }
      }
      Noti.success({
        message: 'Success',
        description: `Your data has been saved successfully.`
      })
      navigate("/setting-document")
    } catch (error) {
      console.log("🚀 ~ postSetting ~ error:", error)
      if(error?.response?.data?.resultCode == "40309"){
        Noti.warning({
          message: 'Duplicate',
          description: "Duplicate Customer ID detected. Please check the data integrity."
        })
        return
      }
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      })
      navigate("/setting-document")
    }
  }
  
  return <>
  <Layout activeKey="setting-document">
    <Helmet>
      <title>Document Manager</title>
    </Helmet>
    <Breadcrumb itemRender={itemRender} items={itemMenu} />
    {loadingFirst ? <SkeletonLoading /> : <>
      <div style={{padding:"20px"}}>
        <div className="header-action">
          <h2 style={{ textAlign: 'left',color:"#646965" }}>Setting Document</h2>
          <div style={{display:"flex", gap:"10px"}}>
            {pageAction != "View" && <Button className="blue-btn" icon={<SaveFilled />} style={{height:"40px", width:"100px"}} onClick={()=> {form.submit()}} disabled={loadingApi}>Save</Button>}
            {pageAction == "View" && <Button className="blue-btn" icon={<EditFilled />} style={{height:"40px", width:"100px"}} onClick={()=>{navigate(`/setting-document/edit/${id}`); setPageAction("Edit")}} disabled={loadingApi}>Edit</Button>}
            <Button className="cancel-btn" icon={<CloseOutlined />} style={{height:"40px", width:"100px"}} onClick={()=>{navigate("/setting-document")}} disabled={loadingApi}>Cancel</Button>
          </div>
        </div>
        <Card className='card-list doc' bordered={false} style={{marginBottom:"20px"}}>
          <Form
            className="form-setting"
            form={form}
            initialValues={initialValues}
            onFinish={(e) => {
              postSetting(e)
            }}
            labelCol={{ sm: { span: 8 }, xl: { span: 10 } }}
            wrapperCol={{ sm: { span: 14 }, xl: { span: 12 } }}
            layout="horizontal"
          >
            <Row gutter={8} className="custom-col">
              <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                <Form.Item
                  name="customerCode"
                  label="Customer ID"
                  style={{ marginBottom: '20px' }}
                  className="star"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input name="customerCode" style={{ width: '100%' }} disabled={loadingApi || pageAction !== 'Add'} maxLength={13}/>
                </Form.Item>
              </Col>
              <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                <Form.Item
                  name="customerName"
                  label="Customer Name"
                  style={{ marginBottom: '20px' }}
                  className="star"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input name="customerName" style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} maxLength={255}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} className="custom-col">
              <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                <Form.Item
                  name="phoneNumber"
                  label="เบอร์โทรศัพท์"
                  style={{ marginBottom: '20px' }}
                  // className="star"
                  rules={[
                    // { required: true, message: '' },
                    // { pattern: /^[0-9\-]+$/, message: '' }
                  ]}
                >
                  <Input name="phoneNumber" style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} maxLength={15}
                    // onKeyDown={(e) => {
                    //   const regex = /^[0-9\-]+$/;
                    //   const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                    //   if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Collapse activeKey={activeKey} onChange={handlePanelChange} style={{ marginTop: '20px' }}>
              <Panel key="1" showArrow={false} header={<CustomPanelHeader title="Invoice set" expanded={activeKey.includes('1')} />}>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Invoice"
                      label="ต้นฉบับใบกำกับภาษี"
                      style={{ marginBottom: '20px' }}
                      className="star"
                      rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Invoice" min={1} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Invoice (copy)"
                      label="สำเนาใบกำกับภาษี"
                      style={{ marginBottom: '20px' }}
                      // className="star"
                      // rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Invoice (copy)" min={0} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Receipt"
                      label="ใบเสร็จรับเงิน"
                      style={{ marginBottom: '20px' }}
                      // className="star"
                      // rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Receipt" min={0} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Receipt (copy)"
                      label="สำเนาใบเสร็จรับเงิน"
                      style={{ marginBottom: '20px' }}
                      // className="star"
                      // rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Receipt (copy)" min={0} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Billing note"
                      label="ใบวางบิล"
                      style={{ marginBottom: '20px' }}
                      // className="star"
                      // rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Billing note" min={0} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '100%' }}>
                    <Form.Item
                      name="invoiceNote"
                      label="รายละเอียดเพิ่มเติม"
                      colon={false}
                      style={{ marginBottom: '20px' }}
                      labelCol={{ sm: { span: 8 }, xl: { span: 5 } }}
                      wrapperCol={{ sm: { span: 14 }, xl: { span: 19 } }}
                    >
                      <TextArea
                        name="invoiceNote"
                        rows={4}
                        placeholder=""
                        className="custom-textarea"
                        onChange={handleInvoiceNoteChange}
                        disabled={loadingApi || pageAction === 'View'}
                        maxLength={1000}
                      />
                    </Form.Item>
                    <div className="textarea-count">{invoiceNoteCount}/1000</div>
                  </Col>
                </Row>
              </Panel>
              <Panel key="2" showArrow={false} header={<CustomPanelHeader title="Delivery Note set" expanded={activeKey.includes('2')} />}>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Delivery note"
                      label="ต้นฉบับใบส่งสินค้าชั่วคราว"
                      style={{ marginBottom: '20px' }}
                      className="star"
                      rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Delivery note" min={1} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Delivery note (copy)"
                      label="สำเนาใบส่งสินค้าชั่วคราว"
                      style={{ marginBottom: '20px' }}
                      // className="star"
                      // rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber name="Delivery note (copy)" min={0} max={20} defaultValue={1} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Packing list"
                      label="Packing list"
                      style={{ marginBottom: '20px' }}
                    >
                      <InputNumber name="Packing list" min={0} max={20} defaultValue={0} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '50%' }}>
                    <Form.Item
                      name="Packing list (copy)"
                      label="Packing list copy"
                      style={{ marginBottom: '20px' }}
                    >
                      <InputNumber name="Packing list (copy)" min={0} max={20} defaultValue={0} style={{ width: '100%' }} disabled={loadingApi || pageAction === 'View'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} className="custom-col">
                  <Col sm={{ flex: '100%', width: '100%' }} xl={{ flex: '100%' }}>
                    <Form.Item
                      name="deliveryNote"
                      label="รายละเอียดเพิ่มเติม"
                      colon={false}
                      style={{ marginBottom: '20px' }}
                      labelCol={{ sm: { span: 8 }, xl: { span: 5 } }}
                      wrapperCol={{ sm: { span: 14 }, xl: { span: 19 } }}
                    >
                      <TextArea
                        name="deliveryNote"
                        rows={4}
                        placeholder=""
                        className="custom-textarea"
                        onChange={handleDeliveryNotesChange}
                        disabled={loadingApi || pageAction === 'View'}
                        maxLength={100}
                      />
                    </Form.Item>
                    <div className="textarea-count">{deliveryNotesCount}/100</div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Form>
          {(otherData.length > 0 || pageAction != "View" ) && <h4>เอกสารอื่นๆ</h4>}
          {pageAction != "View" && <Form
            form={form2}
            initialValues={initialValues2}
            onFinish={(e) => {
              addOther(e)
            }}
            onFinishFailed={()=> {
            }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
          >
            <div className="setting-other-container" style={{marginBottom:"30px"}}>
              <div className="setting-other">
                <div>ชื่อ</div>
                <Form.Item name="name" className="star" rules={[{ required: true, message: '' }]}>
                  <Select
                    disabled={loadingApi || pageAction == "View"}
                    name="name"
                    style={{ width: '200px' }}
                    // onChange={(value) => setSelectedValue(value)}
                    onSelect={(value, option) => setSelectedValue(option)}
                    options={[
                      ...docType.map((item) => ({
                        value: item.documentTypeId,
                        label: item.documentTypeName,
                      })),
                      { value: 'Other', label: 'Other' },
                    ]}
                  />
                </Form.Item>
              </div>
              {selectedValue?.value == "Other" && <div className="setting-other">
                <Form.Item name="nameOther" className="star" rules={[{ required: true, message: '' }]}>
                  <Input name="nameOther" style={{ width: '200px' }} placeholder="โปรดระบุ" disabled={loadingApi || pageAction == "View"} maxLength={30}/>
                </Form.Item>
              </div>}
              <div className="setting-other">
                <div>จำนวน</div>
                <div style={{display:"flex"}}>
                  <Form.Item name="qty" className="star" rules={[{ required: true, message: '' }]}>
                    <InputNumber name="qty" min={1} max={20} defaultValue={1} style={{ width: '200px' }} disabled={loadingApi || pageAction == "View"}/>
                  </Form.Item>
                  {pageAction != "View" && 
                  <Tooltip title="Add other" overlayClassName="tooltip-icon">
                    <Button className="plus-btn" icon={<PlusOutlined />} style={{marginLeft:"10px", minWidth:"44px", minHeight:"44px"}} onClick={()=> {form2.submit()}}></Button>
                  </Tooltip>
                  }
                </div>
              </div>
            </div>
          </Form>}
          {otherData.map((x, index)=> (
            <div className="setting-other-container" style={{alignItems:"center"}}>
              <span>{x.documentTypeName}</span>
              <div>
                <InputNumber min={1} max={20} style={{ width: '200px' }} disabled={loadingApi || pageAction == "View"} value={x.qty} 
                  onChange={(value) => setOtherData((prevData) => prevData.map((item, i) => i === index ? { ...item, qty: value } : item ))}
                />
                {pageAction != "View" && 
                  <Tooltip title="Delete" overlayClassName="tooltip-icon">
                    <Button className="minus-btn" icon={<MinusOutlined />} style={{marginLeft:"10px"}} onClick={() => removeOther(index)}></Button>
                  </Tooltip>
                }
              </div>
            </div>
          ))}
        </Card>
      </div>
    </>}
  </Layout>
  </>;
};

export default App;
